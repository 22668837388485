@import url("https://fonts.googleapis.com/css?family=Muli:300,300i,400,400i,600,600i,700,700i|Comfortaa:300,400,500,700");
$image-background: '../Imagenes/bg_oxioriente_2020.jpg';
$logo:url('../Imagenes/Logo.png');

.inicio-body {
    margin: 0;
    height: 100vh;
    font-family: 'Comfortaa', cursive;
    font-size: 12pt;
}

.inicio-contenedor {
    min-height: 100vh;
    display: flex;
    background-image: url($image-background);
    background-size: cover;
    flex-direction: column;
    justify-content: center;

}

.inicio-subcontenedor {
    max-width: 50vw;
    background-color: white;
    margin-left: 25vw;
    min-height: 70vh;
    border-radius: 15px;

}
.inicio-logo{
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;      
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
    background: -webkit-gradient(linear, left top, left bottom, from(#6d28d9), to(#4c1d95)) !important;
    //background: transparent;
}

.inicio-texto{
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
}


.fs-11{
    font-size: 14px;
    color: gray;
}

.scaled {
    transform: scale(1, 1);
    font-size: 12px !important;
}
.scaled input{
    height: auto !important;
}

.max-sub {
    max-height: 50vh;
}
//Responsive
@media screen and (min-width: 0px) {
    .inicio-subcontenedor{
        max-width: 90vw;
        margin-left: 5vw;
        min-height: 55vh;
    }
    .inicio-logo {
        display: none;
    }
    
}
//Iphone Normal
@media screen and (min-width: 544px) {
    .inicio-subcontenedor{
        max-width: 90vw;
        margin-left: 5vw;
        
    }
  
}

//Iphone Giro
@media screen and (min-width: 768px) {
    .inicio-subcontenedor{
        max-width: 90vw;
        margin-left: 5vw;
        min-height: 90vh;
    }
    .inicio-logo{
        display: flex;
        min-height: 90vh;
    }
    

}

@media screen and (min-width: 992px) {
    .inicio-subcontenedor{
        max-width: 90vw;
        margin-left: 5vw;
    }
    .inicio-logo{
        display: flex;
        min-height: 70vh;
    }
}

@media screen and (min-width: 1200px) {
    .inicio-subcontenedor  {
        max-width: 50vw;
        background-color: white;
        margin-left: 25vw;
        min-height: 70vh;
    }
}