.sbh-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #581c87;
}


.sbh-style{
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
}
.sbh-logo{
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  background-color: #009fdb;
  background: linear-gradient(45deg, rgb(21 87 205) 0%, rgb(90 225 255) 100%);
}

.sbf-footer{
  display: 'flex';  
  align-items: center;
  padding-bottom: 20px;
}

.sbf-style{
  margin-left: 25%;
  width: 50%;
  font-size: 12px;
  display: flex;  
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  color: white;
  background: linear-gradient(45deg, rgb(21 87 205) 0%, rgb(90 225 255) 100%);
}

.fcom {
  font-family: 'Comfortaa', cursive;
}

.sbf-styleFooter {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  color: white;
  background: linear-gradient(45deg, rgb(21 87 205) 0%, rgb(90 225 255) 100%);
}
.sbf-pos { 
  position: relative;
  margin-top: 50px;
  width: 100%; 
  color: white;
}

.sb-sidebar{
  display: 'flex';
  height: 100%;
}

.ly-style {
  display: 'flex';
  flex-direction: 'column';
  height: 100vh;
 

}
.ly-sbh {
  margin-bottom: 24px;
  margin-top: 16px;
}
.ly-layout {
  display: flex;
  height: 100vh;
}

.bg-p {
  background: -webkit-gradient(linear, left top, left bottom, from(#350070), to(#4c1d95)) !important;
  color: white;
  min-width: 100vw;
}


.broken {  
  display: flex;  
  align-items: center;
  min-width: 100vw;
  text-align: right;
  height: 64px;

}

.ly-i-img {
  height: 50px;
  width: 50px;
  background-position: 50%;
  border-radius: 50%;
  border: 2px solid black;
  cursor: pointer;
}


.broken .ly-i-img {
  padding: -150px !important;
}
.hover {
  color: antiquewhite !important;
}
.hover:hover {
  color: antiquewhite;  
  background-color: 'rgba(109,40,217,0.1)' !important;
}

@media screen and (min-width: 544px) {
  .sbf-pos {
    position: relative;
  }  
}

@media screen and (min-width: 992px) {
  .sbf-pos {
    position: absolute;
  }  
}