$color-prinicpal: #360371;

.m-titulo {
    background-color: $color-prinicpal !important;
    color: #ffffff;
    padding: 5px;
    border: 1px black solid;
    font-size: large;
    font-weight: bold;
}

.m-detalle {
    font-size: smaller;
    color: black;
}

.m-detalle .m-asunto {

    background-color: rgba($color: #808080, $alpha: 0.1);
    border-left: 0.1em gray solid;
    border-right: 0.1em gray solid;
    border-bottom: 0.1em gray solid;
    padding: 1em, ;
}
.m-asunto .res{
    font-weight: bold;
    color: $color-prinicpal;
    font-size: larger ;
}

.m-detalle .m-contenido {
    background-color: rgba($color: #808080, $alpha: 0.2);
    border-right: 0.1em gray solid;
    border-bottom: 0.1em gray solid;
    padding: 1em, ;
}